import { useSWRConfig } from 'swr';

import { isErrorResponse } from '@interfaces/BulkAPI';
import { SWR_KEYS } from '@lib/constants';
import { getErrorMessage, log } from '@lib/utils';
import { RemoveCartItemAPIResponse } from 'pages/api/cart/remove-item';

interface ReturnType {
  data?: null;
  error?: string;
}

const removeQueue: (() => Promise<void>)[] = [];
let processing = false;

async function processQueue(mutate) {
  processing = true;

  try {
    while (removeQueue.length > 0) {
      const nextRequest = removeQueue[0];
      await nextRequest();
      removeQueue.shift();
    }
  } catch (error) {
    // remove the failed request from the queue
    removeQueue.shift();
  } finally {
    // All requests processed, update the cart data
    mutate(SWR_KEYS.cart);
    processing = false;
  }
}

export function useRemoveItem({ include }: { include?: string[] }) {
  const { mutate } = useSWRConfig();

  return async ({ id }: { id: string }): Promise<ReturnType> => {
    const request = async () => {
      const response = await fetch('/api/cart/remove-item', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          itemId: id,
          include,
        }),
      });

      try {
        const res: RemoveCartItemAPIResponse = await response.json();
        if (isErrorResponse(res)) {
          return {
            error: res.error.message.join('. '),
          };
        }

        return {
          data: res.data,
        };
      } catch (err) {
        log({ error: err, location: 'useRemoveItem' });
        return {
          error:
            'Unexpected remote server error, may have been unable to remove the item',
        };
      }
    };

    // Add the request to the queue
    const promise: Promise<ReturnType> = new Promise((resolve, reject) => {
      removeQueue.push(async () => {
        try {
          const result = await request();
          resolve(result);
        } catch (error) {
          const message = getErrorMessage(error);
          reject({
            data: null,
            error: message,
          });
        }
      });
    });

    // If there's no processing happening, start processing the queue
    if (!processing) {
      processing = true;
      await processQueue(mutate);
    }

    return promise;
  };
}
