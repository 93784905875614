import { useRouter } from 'next/router';
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSWR from 'swr';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { LoadingFlat } from '@components/Alerts/Loading';
import { Button } from '@components/Button';
import { AddToCartStatus } from '@components/Button/AddToCart';
import { BigCommerceCheckoutButton } from '@components/Button/BigCommerceCheckoutButton';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { CustomImage } from '@components/Image';
import { SlideInPanel } from '@components/SlideInPanel/SlideInPanelNoPortal';
import { BannerH2 } from '@components/Typography/Headings/headings';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { useCart } from '@hooks/cart/useCart';
import { CustomCartItem, UpsellItem } from '@interfaces/ICart';
import { Cart, DigitalItem, PhysicalItem } from '@interfaces/ICart';
import { cartValueWithoutGiftCertificates } from '@lib/cart';
import { blurUrl } from '@lib/images';
import {
  CLOUDINARY_ASSET_FIELDS,
  IMAGE_FIELDS,
} from '@lib/queries/sanity/snippets';
import { cdnClient as client } from '@lib/sanityClient';
import { mergeStyles } from '@lib/styles';
import { log } from '@lib/utils';

import { CartPrice } from '../CartPrice';
import { EmptyCartMessage } from '../EmptyCartMessage';
import { CreateAccountLoyaltyPrompt } from '../LoyaltyPrompt';

import { QuickCartFPT } from './QuickCartFPT';
import { QuickCartGiftCertificate } from './QuickCartGiftCertificate';
import { QuickCartItem } from './QuickCartItem';
import { QuickCartUpsell } from './QuickCartUpsell';

function ItemLoading() {
  return (
    <CustomImage
      alt="loading effect"
      src={blurUrl(470, 120)}
      width={470}
      height={120}
    />
  );
}

function CartSummary({
  cart,
  items,
}: {
  cart?: Cart | null;
  items: CustomCartItem[];
}): ReactElement {
  const [subtotal, setSubtotal] = useState(0);
  const [cartAmount, setCartAmount] = useState(0);

  useEffect(() => {
    let mounted = true;
    if (cart && mounted) {
      const sub =
        [
          ...cart.line_items.digital_items,
          ...cart.line_items.physical_items,
        ].reduce((acc: number, item: PhysicalItem | DigitalItem) => {
          // eslint-disable-next-line no-param-reassign
          acc +=
            item.quantity *
            items.filter(
              (cur: CustomCartItem) =>
                cur.variant?.variantId === item.variant_id
            )[0]?.price;
          return acc;
        }, 0) / 100;

      const withGiftCerts =
        sub +
        cart.line_items.gift_certificates.reduce(
          (acc, cur) => acc + cur.amount,
          0
        );

      setSubtotal(withGiftCerts || cart.cart_amount);
      setCartAmount(cart.cart_amount);
    }
    return function cleanup() {
      mounted = false;
    };
  }, [cart, items]);

  if (!!cart && items.length > 0) {
    return (
      <CartPrice label="Sub total" retail={subtotal} discounted={cartAmount} />
    );
  }
  return <></>;
}

const fetcher = async (url) => {
  const query = `//groq
    *[_type == "quickCartUpsell" 
    && !(_id in path("drafts.**")) 
    && dateTime(startsAt) < dateTime(now())
    && dateTime(expiresAt) > dateTime(now())
    ][0]
    {
      message,
      "image": select(
        image.asset._type == "cloudinary.asset" => image{
          ${CLOUDINARY_ASSET_FIELDS}
        },
        image._type == "image" => image{
          ${IMAGE_FIELDS}
      }),
      "variant": variant->{
        variantId, 
        sku,
        productId,
      },
    }
  `;

  const result = await client.fetch(query);
  if (!result) {
    const error = new Error('Unable to fetch quick cart upsell item');
    throw error;
  }
  return result;
};

export function QuickCart(): ReactElement {
  const { cart, error } = useCart();
  const {
    displayCart: [quickCartIsOpen, setQuickCartIsOpen],
    addingToCart: [addingToCartStatus],
  } = useContext<UIContextInterface>(UIContext);

  const [showUpsellBanner, setShowUpsellBanner] = useState(true);
  const [showFPT, setShowFPT] = useState(false);
  // the base FPT item is used to find it's frequently purchased together items
  const [baseFptItem, setBaseFptItem] = useState<
    PhysicalItem | DigitalItem | null
  >(null);
  const [items, setItems] = useState<CustomCartItem[]>([]); // When we get extra data about the products in the cart

  // Extract line items from cart (memo no need for async)
  const cartItems = useMemo(() => {
    if (cart?.line_items.physical_items || cart?.line_items.digital_items) {
      return [
        ...cart.line_items.physical_items,
        ...cart.line_items.digital_items,
      ];
    } else {
      return [];
    }
  }, [cart?.line_items.physical_items, cart?.line_items.digital_items]);

  // Extract gift certificates from cart (memo no need for async)
  const giftCertificates = useMemo(() => {
    if (cart?.line_items.gift_certificates) {
      return [...cart.line_items.gift_certificates];
    } else {
      return [];
    }
  }, [cart]);

  // periodically get upsell banner
  const { data: upsellItem } = useSWR<UpsellItem>(
    '/api/quickCartUpsell',
    fetcher,
    {
      refreshInterval: 1200000, // 20 minutes
      revalidateOnFocus: false,
    }
  );

  const router = useRouter();

  // close the quick cart on route change
  useEffect(() => {
    const handleRouteChange = () => {
      setQuickCartIsOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  // Work out if upsell banner should be displayed
  useEffect(() => {
    let mounted = true;
    setShowUpsellBanner(true);
    // 1. hide upsell banner when cart is empty
    if (cartItems.length === 0) {
      if (mounted) setShowUpsellBanner(false);
      return function cleanup() {
        mounted = false;
      };
    }

    // 2. hide upsell banner when cart has upsell item already
    if (
      cartItems.findIndex((item) => item.sku === upsellItem?.variant.sku) > -1
    ) {
      if (mounted) setShowUpsellBanner(false);
      return function cleanup() {
        mounted = false;
      };
    }

    return function cleanup() {
      mounted = false;
    };
  }, [addingToCartStatus, cartItems, upsellItem]);

  // Work out if FPT should be displayed
  useEffect(() => {
    let mounted = true;
    let item: PhysicalItem | DigitalItem | null = null;
    // 1. clear FPT when cart is empty
    if (mounted && cartItems.length === 0) {
      setShowFPT(false);
      setBaseFptItem(null);
    } else if (mounted && cartItems.length > 0) {
      item = cartItems[cartItems.length - 1];

      // 2. show FPT when new item is added
      if (
        quickCartIsOpen &&
        (!baseFptItem || (baseFptItem && baseFptItem.sku !== item.sku))
      ) {
        setShowFPT(true);
        setBaseFptItem(item);
      }

      // 3. hide FPT when item is removed
      if (
        quickCartIsOpen &&
        baseFptItem &&
        cartItems.findIndex((item) => item.sku === baseFptItem.sku) === -1
      ) {
        setShowFPT(false);
      }

      // 4. hide FPT when cart is closed
      if (!quickCartIsOpen && baseFptItem && baseFptItem.sku === item.sku) {
        setShowFPT(false);
      }
    }

    return function cleanup() {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickCartIsOpen, cartItems]);

  // Pull out the IDs and fetch additional data on products
  useEffect(() => {
    let mounted = true;

    // Get the product and variant ids so we can get more details
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      // Get the product and variant ids so we can get more details
      const variantIds: number[] = cartItems.reduce(
        (acc: number[], cur: PhysicalItem | DigitalItem) => {
          acc.push(cur.variant_id);
          return acc;
        },
        [] as number[]
      );

      const productIds: number[] = cartItems.reduce(
        (acc: number[], cur: PhysicalItem | DigitalItem) => {
          acc.push(cur.product_id);
          return acc;
        },
        [] as number[]
      );

      if (productIds.length > 0 || variantIds.length > 0) {
        //. Try fetch the additional cart data. If it fails not too big of a deal, just gracefully return early
        try {
          const res = await fetch('/api/customer/getCart', {
            method: 'POST',
            body: JSON.stringify({ productIds, variantIds }),
          });

          if (res.status !== 200) {
            log({
              error: 'Failed to fetch additional cart data',
              location: 'QuickCart',
            });
            return;
          }

          const { data: cartItems } = await res.json();
          if (mounted) {
            setItems(cartItems);
          }
        } catch (err) {
          log({ error: err, location: 'QuickCart getCart' });
        }
      }
    })();
    return function cleanup() {
      mounted = false;
    };
  }, [cartItems]);

  if (error) {
    return (
      <SlideInPanel isOpen={quickCartIsOpen} toggleState={setQuickCartIsOpen}>
        <BannerH2>Error retrieving cart</BannerH2>
        <Paragraph>
          We were unable to retrieve a cart from the server at this time.
        </Paragraph>
        <pre>{error.message}</pre>
      </SlideInPanel>
    );
  }

  if (
    ((cart && cartItems.length === 0 && giftCertificates.length === 0) ||
      cart === undefined ||
      cart == null) &&
    addingToCartStatus !== AddToCartStatus.Adding
  ) {
    return (
      <SlideInPanel isOpen={quickCartIsOpen} toggleState={setQuickCartIsOpen}>
        <BannerH2>Nothing in your cart</BannerH2>
        <EmptyCartMessage />
      </SlideInPanel>
    );
  }

  return (
    <SlideInPanel
      isOpen={quickCartIsOpen}
      toggleState={setQuickCartIsOpen}
      scrollable={false}
    >
      <section className="flex h-[94vh] flex-col justify-between">
        <BulkErrorBoundary location="QuickCart">
          <BannerH2>Your Cart</BannerH2>
          <div
            className={mergeStyles(
              showFPT ? 'has-fpt' : '',
              'h-full overflow-y-scroll'
            )}
          >
            {cartItems.map((lineItem: PhysicalItem | DigitalItem) => {
              const item = items?.filter(
                (ci) => ci.variant?.variantId === lineItem.variant_id
              )[0];
              if (item) {
                return (
                  <QuickCartItem
                    item={item}
                    lineItem={lineItem}
                    key={lineItem.id}
                  />
                );
              } else {
                return <ItemLoading key={lineItem.id} />;
              }
            })}

            <div
              className={`${
                addingToCartStatus !== AddToCartStatus.Adding &&
                showFPT &&
                baseFptItem
                  ? 'animate-[fadeInFromLeft_0.25s_forwards]'
                  : 'hidden'
              } fpt-container -translate-x-full opacity-0`}
            >
              {baseFptItem && <QuickCartFPT cartItem={baseFptItem} />}
            </div>

            {giftCertificates.map((cert) => (
              <QuickCartGiftCertificate key={cert.id} certificate={cert} />
            ))}
          </div>
          <div className="flex flex-col bg-white dark:bg-black">
            <QuickCartUpsell
              showUpsellBanner={showUpsellBanner}
              upsellItem={upsellItem}
            />
            {showUpsellBanner && <HorizontalDivider className="my-2.5" />}
            <div className="left-0 right-0 flex items-center justify-center gap-5 p-2.5">
              <Button href="/cart">View Cart</Button>
              <BigCommerceCheckoutButton styledButton={true} />
              <CartSummary cart={cart} items={items} />
            </div>
            <HorizontalDivider className="my-2.5 via-grey-mid" />
            {cart ? (
              <CreateAccountLoyaltyPrompt
                cartValue={cartValueWithoutGiftCertificates(cart)}
              />
            ) : null}
          </div>
        </BulkErrorBoundary>
      </section>
    </SlideInPanel>
  );
}
