import Image from 'next/legacy/image';
import React, { ReactElement, useState } from 'react';
import { BsTrash } from 'react-icons/bs';

import { Loading } from '@components/Alerts/Loading';
import { CartPrice } from '@components/Cart/CartPrice';
import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useRemoveItem } from '@hooks/cart/useRemoveItem';
import { GiftCertificate } from '@interfaces/ICart';
import { log } from '@lib/utils';

export function QuickCartGiftCertificate({
  certificate,
}: {
  certificate: GiftCertificate;
}): ReactElement {
  const removeItem = useRemoveItem({
    include: ['line_items.physical_items.options'],
  });
  const [updating, setUpdating] = useState(false);
  /**
   * We need to do this because the type is wrong otherwise
   * Putting this in state rather than a const allows us to set it to null
   * Which allows the hack of showing an empty div if null, after removed from cart.
   * This is needed because forceUpdate was here...but not working ¯\_(ツ)_/¯
   */
  const [cert, setCert] = useState<GiftCertificate | null>({
    ...certificate,
  });

  const handleRemove = async () => {
    setUpdating(true);

    try {
      await removeItem({ id: certificate.id });
    } catch (error) {
      log({ error, location: 'QuickCartGiftCertificate handleRemove' });
    } finally {
      setCert(null);
      // in case of error, set back to false so opacity resets
      setUpdating(false);
    }
  };

  if (!cert) return <div />;

  return (
    <div className={updating ? 'opacity-50' : ''}>
      <div className="grid grid-cols-[1fr_3fr] px-0 py-4 md:grid-cols-[1fr_4fr]">
        <TextLink href="/giftcertificates">
          <figure
            style={{
              margin: '0px auto',
              width: 'fit-content',
            }}
          >
            <Image
              src="/images/Bulk-Nutrients-gift-certificate.webp"
              alt="gift certificate render "
              width={85}
              height={85}
              className="object-scale-down object-center"
            />
          </figure>
        </TextLink>
        <div>
          <button
            type="button"
            onClick={handleRemove}
            className="float-right border-none bg-transparent px-2.5 py-1 uppercase text-grey-dark hover:text-orange dark:text-white dark:hover:text-orange"
          >
            {updating ? (
              <Loading className="right-[10px] top-[-10px] h-[20px] w-[20px] scale-[.35]" />
            ) : (
              <BsTrash size={20} />
            )}
          </button>
          <div className="flex gap-1 py-2 leading-5">
            <TextLink href="/giftcertificates" target="_top">
              {cert.name}
            </TextLink>
            <span>for {cert.recipient.name}</span>
          </div>
          <div className="grid grid-cols-[1fr_2fr_2fr] gap-1">
            <div className="flex flex-col font-secondary">
              <span className="text-s uppercase text-grey-mid">Quantity:</span>
              <span>1</span>
            </div>
            <CartPrice
              retail={cert.amount}
              discounted={cert.amount}
              label="Unit Price:"
            />
            <CartPrice
              retail={cert.amount}
              discounted={cert.amount}
              label="Total:"
            />
          </div>
        </div>
      </div>
      <HorizontalDivider className="m-0 via-grey-mid" />
    </div>
  );
}
