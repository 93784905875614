/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useSWR from 'swr';
import { twJoin } from 'tailwind-merge';

import { Container } from '@components/Container';
import { PortableTextBlockRenderer } from '@components/PortableText/PortableText';
import { SkipTo } from '@components/SkipTo/SkipTo';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { BulkAPIErrorResponse } from '@interfaces/BulkAPI';
import { SWR_KEYS } from '@lib/constants';
import { log } from '@lib/utils';

function Alert({
  content,
  isActive,
  duration,
  animate,
}: {
  content: any;
  isActive: boolean;
  duration: number;
  animate: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const itemEntrance = useCallback(
    (item: HTMLDivElement) => {
      const slideInAnimation = [
        { transform: 'translateX(100%)', opacity: 0 },
        { transform: 'translateX(0%)', opacity: 1, offset: 0.05 },
        { transform: 'translateX(0%)', opacity: 1, offset: 0.95 },
        {
          transform: 'translateX(-100%)',
          opacity: 0,
        },
      ];
      const slideInTiming = {
        duration: duration,
        iterations: 1,
      };
      item.animate(slideInAnimation, slideInTiming);
    },
    [duration]
  );

  useEffect(() => {
    if (isActive && ref.current !== null && animate) {
      itemEntrance(ref.current);
    }
  }, [isActive, itemEntrance, animate]);

  const positionClasses = animate
    ? 'opacity-0 translate-x-full'
    : 'opacity-100 translate-x-0';

  return (
    <div
      ref={ref}
      className={twJoin([
        'col-span-full row-span-full w-full text-center',
        positionClasses,
      ])}
    >
      <PortableTextBlockRenderer
        content={content}
        componentsOverride={{
          block: {
            normal: ({ children }: { children?: ReactNode }) => (
              <p className="m-0">{children}</p>
            ),
          },
        }}
      />
    </div>
  );
}

function RotatingAlerts({ alerts }: { alerts: any }) {
  const [activeIdx, setActiveIdx] = useState<number>(0);

  const duration = 5000;

  // Set the alerts animating
  useEffect(() => {
    let mounted = true;

    if (mounted && alerts.length === 0) {
      setActiveIdx(0);
      return;
    }

    setTimeout(() => {
      if (mounted) setActiveIdx((activeIdx + 1) % alerts.length);
    }, duration);

    return function cleanup() {
      mounted = false;
    };
  }, [activeIdx, alerts]);

  return (
    <div className="relative grid w-full grid-cols-1 overflow-hidden whitespace-nowrap">
      {alerts.map((alert: any, idx: number) => (
        <Alert
          key={alert._id}
          content={alert.content}
          isActive={activeIdx === idx}
          duration={duration}
          animate={alerts.length > 1}
        />
      ))}
    </div>
  );
}

const fetcher = async (url) => {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (res.status !== 200) {
    const {
      error: { message, code },
    } = (await res.json()) as BulkAPIErrorResponse;
    const error = new Error('Unable to fetch alerts');
    error.message = message.join('. ');
    throw error;
  }

  const { data: alerts } = await res.json();
  return alerts;
};
export function InfoNavbar() {
  const { data: alerts, error } = useSWR(SWR_KEYS.alerts, fetcher, {
    refreshInterval: 300000, // 5 minutes
    revalidateOnFocus: false,
    onError: (error) => {
      log({ error, location: 'InfoNavbar useSWR' });
    },
  });

  // Applied dark class in container to avoid drama with portable text links. Easier than an override component and adding classes to other links
  return (
    <>
      <Container className="dark bg-grey-darkest">
        <div className="full-bleed bg-transparent py-1 text-xs font-light leading-3 text-grey-dark dark:text-white">
          <div className="relative mx-auto my-0 block min-h-5 max-w-maxWidth px-2 py-1 sm:flex sm:justify-center lg:justify-between">
            <SkipTo />

            <div className="hidden text-nowrap lg:inline-block">
              <TextLink href="/about-us">Why Bulk Nutrients?</TextLink>
            </div>

            <div className={twJoin(['header-alert', 'grow-1'])}>
              {!error && alerts && <RotatingAlerts alerts={alerts} />}
            </div>

            <TextLink
              href="/contact-us"
              className="hidden text-nowrap lg:inline-block"
            >
              Need Help?
            </TextLink>
          </div>
        </div>
      </Container>
    </>
  );
}
